import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../layouts/index"

export default ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <div className="content-wrap blog-list">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div className="blog-item" key={node.id}>
            <div className="blog-item-info">
              <Link className="blog-title" to={node.fields.slug}>
                {node.frontmatter.title}
              </Link>
              <h5>
                作者：{node.frontmatter.author}
                <span>发布日期：{node.frontmatter.date}</span>
              </h5>
              <p>{node.frontmatter.brief}</p>
              <Link className="read-more" to={node.fields.slug}>
                阅读全文
              </Link>
            </div>
            <div
              className={
                node.frontmatter.thumbnail === "0"
                  ? "thumbnail no-img"
                  : "thumbnail"
              }
            >
              <img src={node.frontmatter.thumbnail} alt="" />
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            author
            brief
            thumbnail
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
